import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Card, Image, Input, Modal, Space, Table, Tooltip } from "antd";
import { DateFormat } from "components/helpers/DateFormat";
import { DEFAULT_PAGE_SIZE } from "constants/ApiConstant";
import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Service from "services/ConsultationTypicalResponseService";



const TypicalResponseList = () => {
    console.log('typical list')
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");
    const [src, setSrc] = useState();
    const [visible, setVisible] = useState(false);
    const [page, setPage] = useState(0);

    const intl = useIntl();
    const columns = [
        {
            title: 'ID',
            dataIndex: "id",
        },
        {
            title: 'Titre',
            dataIndex: "title",
        },
        {
            title: "Docteur",
            dataIndex: "owner",
            render: (owner) => owner.name
        },

    ]

    const loadList = async (pageNo, pageSize, search) => {
        setLoading(true)
        await Service.findAll(pageNo, pageSize, search).then((data) => {
            setLoading(false);
            console.log(data)
            setPage(data);
        });
    };

    useEffect(() => {
        loadList(0, DEFAULT_PAGE_SIZE, "");
    }, []);



    // const add = () => history.push(`${APP_PREFIX_PATH}/eprescription/typical/details`);

    return (
        <Card
            loading={loading}
            extra={
                <Space>
                    <Input onChange={(change) => {
                        setSearch(change.target.value);
                    }} />

                    <Tooltip
                        title={<FormattedMessage id={"eprescription.typical.list.search"} />}
                    >
                        <Button
                            type="primary"
                            // style={{ backgroundColor: PRIMARY_COLOR, color: "#fafafb" }}
                            shape={"circle"}

                            onClick={() => {
                                loadList(0, DEFAULT_PAGE_SIZE, search)
                            }}
                            icon={<SearchOutlined />}
                        />
                    </Tooltip>

                </Space>

            }
        >

            <div className="table-responsive">
                <Table
                    rowKey={"id"}
                    columns={columns}
                    onChange={(pagination) => loadList(pagination.current - 1, pagination?.pageSize)}
                    pagination={{
                        total: page?.totalElements,
                        pageSize: page?.pageable?.pageSize,
                        current: page?.pageable?.pageNumber + 1,
                        hideOnSinglePage: true
                    }}
                    expandable={{
                        expandedRowRender: (record) => (
                            <div>
                                <div>
                                    <h3>INDICATION</h3>
                                    <p
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        {record.indication}
                                    </p>
                                </div>

                                <div>
                                    <h3>TECHNIQUE</h3>
                                    <p
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        {record.technique}
                                    </p>
                                </div>

                                <div>
                                    <h3>INTERPRETATION</h3>
                                    <p
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        {record.interpretation}
                                    </p>
                                </div>
                                <div>
                                    <h3>CONCLUSION</h3>
                                    <p
                                        style={{
                                            marginLeft: "20px",
                                        }}
                                    >
                                        {record.conclusion}
                                    </p>
                                </div>
                           
                            </div>
                        ),
                    }}
                    loading={loading}
                    rowClassName={() => "table-row-cursor"}
                    dataSource={page && page.content}
                />
            </div>
        </Card>
    );
}

export default TypicalResponseList;