import { create } from "zustand";
import fetch from "../../auth/FetchInterceptor";
import { alertMessage } from "hooks/useUtils";
import {
  APPROUVE_ENTERPRISE,
  DOCTOR_LIST_ENTRY_POINT,
} from "constants/ApiConstant";
import moment from "moment";

export const useDoctorOnDuty = create((set, get) => ({
  loading: false,
  listDoctors: [],
  endpoint: "v2/yata/doctor-on-duty",
  error: null,
  doctors: [],

  fetchDoctorsRequest: () => {
    set({ loading: true });
    fetch
      .get(get().endpoint)
      .then((response) =>
        set({ loading: false, listDoctors: response.reverse() })
      )
      .catch((error) => set({ loading: false, error: error?.message }));
  },

  fetchDoctors: () => {
    set({ loading: true });
    fetch
      .get(
        DOCTOR_LIST_ENTRY_POINT +
          `?page=${0}&size=${12}&status=ACTIVE&speciality=`
      )
      .then((response) => {
        set({ loading: false, doctors: response.content.reverse() });
      })
      .catch((error) => set({ loading: false, error: error?.message }));
  },

  approveDoctorOnDutyRequest: (idRequest, action) => {
    set({ loading: true });

    fetch
      .patch(get().endpoint + `/${idRequest}/?request=${action}`)
      .then((response) => {
        alertMessage(APPROUVE_ENTERPRISE, "success");
        set((state) => {
          state.loading = false;
          state.fetchDoctorsRequest();
          return state;
        });
      })
      .catch((error) => {
        alertMessage(error?.message, "error");
        set({ loading: false });
      });
  },

  addDoctorOnDutyRequest: (payload, id) => {
    set({ loading: true });

    payload["doctorId"] = id;
    payload["date"] = moment(payload?.date).format("YYYY-MM-DD");
    payload["start"] = moment(payload?.start).format("HH:mm:ss");
    payload["end"] = moment(payload?.end).format("HH:mm:ss");

    fetch
      .post(get().endpoint, payload)
      .then((response) => {
        alertMessage("Added", "success");

        set({ loading: false, payload: [] });
      })
      .catch((error) => {
        alertMessage(error?.message, "error");
        set({ loading: false, error: error?.message });
      });
  },

  handleRequestFilter: (searchConstraint, filterType) => {
    set({ loading: true });
    const requestParam =
      filterType === "byDate"
        ? "date"
        : filterType === "byStatus"
        ? "request"
        : "id";
    console.log("==>", requestParam);
    fetch
      .get(get().endpoint + `?${requestParam}=${searchConstraint}`)
      .then((response) => {
        set({ loading: false, listDoctors: response.reverse() });
      })
      .catch((error) => set({ loading: false, error: error?.message }));
  },

  updateOnDutyRequest: (payload, id) => {
    console.log("first", payload, id);
    set({ loading: true });
    fetch
      .put(get().endpoint + `/${id}`, payload)
      .then((response) => {
        alertMessage("Updated successfully!!", "success");
        set((state) => {
          state.loading = false;
          state.fetchDoctorsRequest();
          return state;
        });
      })
      .catch((error) => set({ error: error?.message, loading: false }));
  },
}));
