import { Select } from "antd";
import React from "react";
import { useDoctorOnDuty } from "zustand/store/doctorOnDutyStore";

const AppSelect = (props) => (
  <Select
    size={props?.size}
    showSearch={props?.showSearch}
    style={props?.style}
    placeholder={props?.placeholder}
    optionFilterProp="children"
    onSelect={(value, option) => props?.setSelectedId(option?.id)}
    filterOption={(input, option) =>
      (option?.name.toLowerCase() ?? "").includes(input.toLowerCase())
    }
    filterSort={(optionA, optionB) =>
      (optionA?.name ?? "")
        .toLowerCase()
        .localeCompare((optionB?.name ?? "").toLowerCase())
    }
    options={props?.listOfOptions}
  />
);

export default AppSelect;
