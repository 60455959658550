import { Card, Row } from "antd";
import { MARGIN } from "constants/ThemeConstant";
import React from "react";

const CustomCard = (props) => (
  <Card
    style={{
      width: "100%",
    }}
    title={
      <div style={{ marginTop: MARGIN, marginBottom: MARGIN }}>
        <Row>
         {props?.customSelect}
         {props?.customSelect2}
         {props?.customSelect3}
          {props?.customSearch}
          {props?.selectStatus}
         
        </Row>
      </div>
    }
    {...props}
  >
    {props?.children}
  </Card>
);

export default CustomCard;
