import { create } from "zustand";
import fetch from "../../auth/FetchInterceptor";
import { alertMessage, goBack } from "hooks/useUtils";

export const usePatient = create((set, get) => ({
  loading: false,
  patientBalance: 0.0,
  error: null,

  fetchPatientBalance: (id) => {
    set({ loading: true });
    fetch
      .get(`v2/yata/billing/payments/patient-balance?patient=${id}`)
      .then((res) => {
        set({ loading: false, patientBalance: res?.amount });
      })
      .catch((error) => {
        set({ loading: false });
      });
  },

  updatePatientBalance: (payload) => {
    set({ loading: true });
    fetch
      .post(`v2/yata/billing/payments/patient-balance/update`, payload)
      .then((res) => {
        console.log("res", res);
        alertMessage("Operation effectuee avec success!!", "success");
        set({ loading: false });
        goBack();
      })
      .catch((error) => {
        set({ loading: false });
      });
  },

  withdrawPatientAccount: (amount, id) => {
    set({ loading: true });
    fetch
      .get(`v2/yata/billing/patient-balance?patient=${id}`)
      .then((res) => {
        console.log("first", res);
      })
      .catch((error) => {});
  },
}));
