import { Button, Card, Divider, Form, Image, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { CANCEL, DOCTOR, JUSTIFICATION, SAVE } from "constants/contentText";
import React, { useState } from "react";
import CustomButton from "utils/appointment/customButton";
import CustomCard from "utils/customCard";
import AppSelect from "utils/selectComponent";

const ClaimsDetail = (props) => {
  const [form] = Form.useForm();
  const claim = props.location.state;

  const [mode, setMode] = useState("");

  const onFinish = (values) => {
    console.log("first", values);
  };
  const onFinishFailed = () => {};

  const handleAction = (mode) => {
    return () => setMode(mode);
  };

  return (
    <Form
      initialValues={{
        remember: true,
        description: claim?.description,
        serviceName: claim?.serviceName,
        media: claim?.media,
        doctorID: "",
        comment: "",
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="on"
      layout="horizontal"
      form={form}
    >
      <CustomCard
        title={"Detail de la reclammation"}
        extra={
          !!mode && (
            <div style={{ display: "flex" }}>
              <Form.Item style={{ marginLeft: "10px", marginRight: "10px" }}>
                <CustomButton
                  textbutton={"SEND"}
                  htmlType="submit"
                  type="primary"
                />
              </Form.Item>

              <Form.Item>
                <CustomButton
                  textbutton={CANCEL}
                  handleClick={() => setMode("")}
                  type="danger"
                  ghost
                />
              </Form.Item>
            </div>
          )
        }
      >
        <Form.Item label={"SERVICENAME"} name="serviceName">
          <Input size="large" disabled={true} />
        </Form.Item>

        <Form.Item label={"DESCRIPTION"} name="description">
          <TextArea disabled={true} />
        </Form.Item>

        {!!mode && (
          <>
            <Form.Item
              label={"COMMENT"}
              name="comment"
              rules={
                mode === "COMMENT" && [
                  {
                    required: true,
                    message: "comments required!!",
                  },
                ]
              }
            >
              <TextArea />
            </Form.Item>

            {mode !== "COMMENT" && (
              <Form.Item
                label={DOCTOR}
                name="doctorID"
                // rules={[
                //   {
                //     required: true,
                //     message: "Doctor required!!",
                //   },
                // ]}
              >
                <AppSelect
                  showSearch="Assigner a un docteur"
                  placeholder="Assigner a un docteur"
                  listOfOptions={[
                    { id: 1, name: "Kemal", value: 1 },
                    { id: 2, name: "Dr.", value: 2 },
                  ]}
                />
              </Form.Item>
            )}
          </>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <Form.Item>
            <CustomButton
              textbutton="Assigner a un docteur"
              handleClick={handleAction("ASSIGN")}
              type="primary"
              ghost
            />
          </Form.Item>
          <Form.Item style={{ marginLeft: "10px", marginRight: "10px" }}>
            <CustomButton
              textbutton="Comment"
              handleClick={handleAction("COMMENT")}
              type="primary"
              ghost
            />
          </Form.Item>
        </div>

        <Divider orientation="center">{JUSTIFICATION}</Divider>

        <Card>
          {claim &&
            claim?.media?.map((media) => (
              <Image key={media?.id} src={media?.url} sizes="20" />
            ))}
        </Card>
      </CustomCard>
    </Form>
  );
};

export default ClaimsDetail;
