import React from "react";
import { Button } from "antd";

const CustomButton = (props) => {
  const { handleClick, textbutton } = props;

  return (
    <Button onClick={handleClick} {...props}>
      {textbutton}
    </Button>
  );
};

export default CustomButton;
