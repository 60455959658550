export const APPROVED = "Approuvé";
export const NOTAPPROVED = "N'est pas approuvé!!";
export const ERROR = "Erreur!!";

export const ACCEPT = "accept";
export const REJECT = "refuse";
export const DEACTIVATE = "Desactiver";
export const ACTIVATE = "Activer";
export const DEACTIVATED = "Desactif";
export const ACTIVATED = "Actif";
export const BLOCK = "Bloqué";
export const UNBLOCK = "Débloqué";
export const APPROUVE_BUTTON = "Approuver";
export const REJECT_BUTTON = "Refuser";

export const DISABLE = "inactif";
export const DISABLE_STATUS = "Inactive";

export const ENABLE = "active";
export const ENABLE_STATUS = "Active";
export const OPERATION_SUCCESS = "Effectuer avec succes";

export const DRAFT = "DRAFT";
export const SUBMITTED = "SUBMITTED";
export const ALL = "ALL";
export const APPOINTMENT_CANCELED = "Rendez-vous annulé !!!";
export const METHOD_ADDED = "Methode ajouté avec succes";
export const METHOD_UPDATED = "Methode modifié avec succes!";
export const INFOS_SCHEDULE = "Information des planings de RDV";
export const START_DATE = "Date de debut";
export const END_DATE = "Date de fin";
export const DURATION = "Duree";
export const PRICE = "Prix";
export const SCHEDULE = "Emploi de temps";
export const MINUTES = "minutes";
export const MRU = "MRU";
export const DATE = "Date";
export const PATIENT = "Patient";
export const PATIENT_NUMBER = "Numero du patient";
export const DOCTOR = "Docteur";
export const STATUS = "Statut";
export const SECRETER = "Secretaire";
export const OFFICE = "Cabinet";
export const ACTION = "Action";
export const ADD = "Ajouter";
export const CANCEL = "Annuler";
export const DOCTOR_NAME = "Nom du docteur";
export const DOCTOR_NUMBER = "Numero du docteur";
export const DOCTOR_SPECIALITY = "Specialite du docteur";
export const SERVICE = "Service";
export const CONSULTATION_PRICE = "Prix de consultation";
export const ENTERPRISE_LOCATION = "Lieu de l'etablissement";
export const SECRETER_NUMBER = "Numero du secretaire";
export const CREATE_ENTERPRISE = "Creer une entreprise";
export const SELECT_ENTERPRISE = "Selectionner une entreprise";
export const ENTERPRISE_REQUEST_INFORMATION = "Information de la demande";
export const ENTERPRISE_NAME = "Nom d'etablissement";
export const DOCTOR_PLAN = "Planning des RDV";
export const AMOUNT = "Montant";
export const LABEL = "Label";
export const FEE = "Frais";
export const PAID_AMOUNT = "Montant paye";
export const OUTSTANDING_AMOUNT = "Montant restant";
export const TOTAL = "Total";
export const DETAIL = "Detail";
export const PAYMENT_MODE = "Mode de paiement";
export const CODE = "Code commerçant";
export const JUSTIFICATION = "Justificatif";

export const PAYMENT_DETAIL = "Detail du paiement";
export const SAVE = "Enregistrer";
export const AMOUNT_TO_PAY = "Montant a payer";

export const PAYMENT_STATUS = "Statut du payment";
export const REASON = "Raison";
export const INVOICE = "Facture";
export const PAYMENT_METHOD = "Methode de paiement";
export const ADD_PAYMENT_METHOD = "Ajouter methode de paiement";
export const WALLET_NAME = "Nom du wallet";
export const IMAGE = "Image";
export const MODIFY = "Modifier";
export const DELETE = "Supprimer";
export const ALL_PAYMENTS = "Tous";
export const PAID = "Payé";
export const DRAFT_PAYMENT = "En attente de paiement";
export const BAD_IMAGE = "L'image est de mauvaise qualite";
export const FRAUD_DETECTED = "Detection de fraude";
export const ACCEPT_PAYMENT = "Accepter";
export const REFUSE_PAYMENT = "Refuser";
export const PENDING = "En attente";
export const AWAITING_PAYMENT = "En attente de paiement";
export const WAITING_BILLING = "En attente de facturation";
export const CANCELED = "Annule";
export const IN_ACTION = "En cours";
export const WAITING_GEOLOCATION = "En attente de geolocalisation";
export const COMPLETED = "Complet";

export const FIELD_TEAM_ON_PROCESSING = "L’équipe en cours de déplacement!";
export const FIELD_TEAM_IN_ZONE = "L'équipe est arrivée sur place!";
export const SERVICE_COULDNOT_BE_PERFORMED = "Prestation n'a pas ete effectue";
export const SAMPLING_IN_LABORATORY = "Prélèvement remis au laboratoire";
export const RESULT_AVAILABLE = "Résultat sorti";
