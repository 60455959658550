import {
  DashboardOutlined,
  UserOutlined,
  BugOutlined,
  FileOutlined,
  ExceptionOutlined,
  FileDoneOutlined,
  BookOutlined,
  CloudUploadOutlined,
  VideoCameraOutlined,
  ThunderboltOutlined,
  MoneyCollectOutlined,
  DollarOutlined,
  NotificationOutlined,
  BankOutlined,
  FileImageOutlined,
  ConsoleSqlOutlined,
  AlignCenterOutlined,
  AppstoreOutlined,
  CodeOutlined,
  FileProtectOutlined,
  HistoryOutlined,
  UnorderedListOutlined,
  MedicineBoxOutlined,
  ExperimentOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const BiosensorNavTree = [
  {
    key: "biosensor",
    path: `${APP_PREFIX_PATH}/biosensors`,
    title: "biosensor",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "biosensor.listPatient",
        path: `${APP_PREFIX_PATH}/biosensors/patches`,
        title: "biosensors.patches",
        icon: MedicineBoxOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "biosensor.listContract",
        path: `${APP_PREFIX_PATH}/biosensors/contracts`,
        title: "biosensors.contracts",
        icon: MedicineBoxOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const invoices = [
  {
    key: "invoices",
    path: "",
    title: "Gestion des factures",
    icon: DollarOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "#methods",
        path: `${APP_PREFIX_PATH}/payments/payment-methods`,
        title: "Methodes de paiement ",
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "pay",
        path: `${APP_PREFIX_PATH}/appointment/payments/listAppointments`,
        title: "Payments",
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const appointments = [
  {
    key: "appointments",
    path: `${APP_PREFIX_PATH}/appointment`,
    title: "Rendez-vous",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "appointments.online",
        path: `${APP_PREFIX_PATH}/appointment/online`,
        title: "Rendez-vous en ligne",
        icon: MedicineBoxOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "appointments.office",
        path: `${APP_PREFIX_PATH}/appointment/office`,
        title: "Rendez-vous au cabinet",
        icon: MedicineBoxOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const homeServices = [
  {
    key: "homeServices",
    path: `${APP_PREFIX_PATH}/home-care-requests`,
    title: "Prestation a domicile",
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "homecare",
        path: `${APP_PREFIX_PATH}/home-care-requests`,
        title: "Soins a domicile",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "appointments",
        path: `${APP_PREFIX_PATH}/in-home-sampling-requests`,
        title: "Prelevement a domicile",
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const claims = [
  {
    key: "claims",
    path: `${APP_PREFIX_PATH}/claims-request`,
    title: "Reclammation",
    breadcrumb: true,
    submenu: [
      {
        key: "claims.list",
        path: `${APP_PREFIX_PATH}/claims-request`,
        title: "Reclammation",
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const usersRelatedNavTree = [
  {
    key: "doctors",
    path: `${APP_PREFIX_PATH}/doctors`,
    title: "sidenav.users",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "demande de creation",
        path: `${APP_PREFIX_PATH}/requests`,
        title: "creation de compte",
        icon: UsergroupAddOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "medecins",
        path: `${APP_PREFIX_PATH}/doctors`,
        title: "medecins",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "medecins_de_garde",
        path: `${APP_PREFIX_PATH}/doctors/on-duty`,
        title: "medecins de garde",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "healthprofessional",
        path: `${APP_PREFIX_PATH}/healthprofessional`,
        title: "professionnel de santé",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "patients",
        path: `${APP_PREFIX_PATH}/patients`,
        title: "Patients",
        icon: UserOutlined,
        breadcrumb: false,
        submenu: [],
      },
      // {
      //   key: "uloadlist",
      //   path: `${APP_PREFIX_PATH}/doctors/uploadlist`,
      //   title: "List de Garde",
      //   icon: UserOutlined, // ClockCircleOutlined,
      //   breadcrumb: false,
      //   submenu: [],
      // },
      {
        key: "gestion_enterprise",
        path: `${APP_PREFIX_PATH}/enterprise`,
        title: "gestion_enterprise",
        icon: BankOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "approve_enterprise",
            path: `${APP_PREFIX_PATH}/enterprise/approve_enterprise`,
            title: "Approuver la demande",
            icon: BankOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "enterprise",
            path: `${APP_PREFIX_PATH}/enterprise`,
            title: "enterprise",
            icon: BankOutlined,
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "laboratory",
            path: `${APP_PREFIX_PATH}/laboratory`,
            title: "Laboratoire",
            icon: DashboardOutlined,
            breadcrumb: false,
            submenu: [],
          },
          // {
          //   key: "requested-Doctors",
          //   path: `${APP_PREFIX_PATH}/requested-doctors`,
          //   title: "Requested Doctors",
          //   icon: AlignCenterOutlined,
          //   breadcrumb: false,
          //   submenu: [],
          // },
        ],
      },
      {
        key: "medecins.notifiaction",
        path: `${APP_PREFIX_PATH}/notification`,
        title: "medecins.notifiaction",
        icon: NotificationOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const logsRelatedNavTree = [
  {
    key: "development",
    path: `${APP_PREFIX_PATH}/logs`,
    title: "development",
    icon: "",
    breadcrumb: false,
    submenu: [
      {
        key: "logs",
        path: `${APP_PREFIX_PATH}/logs`,
        title: "logs",
        icon: CodeOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "errors",
        path: `${APP_PREFIX_PATH}/logs/errors/list`,
        title: "Errors",
        icon: BugOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const consultationRelatedNavTree = [
  {
    key: "consultation",
    path: `${APP_PREFIX_PATH}/consultation`,
    title: "consultation",
    icon: BugOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "consultation.new",
        path: `${APP_PREFIX_PATH}/consultation/list/new`,
        title: "consultation.new",
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "consultation.wait",
        path: `${APP_PREFIX_PATH}/consultation/list/wait`,
        title: "consultation.wait",
        icon: ExceptionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "consultation.terminate",
        path: `${APP_PREFIX_PATH}/consultation/list/terminate`,
        title: "consultation.terminate",
        icon: FileDoneOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "consultation.dicoms",
        path: `${APP_PREFIX_PATH}/dicoms/list`,
        title: "consultation.dicoms",
        icon: FileImageOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "folder_creation_requests",
        path: `${APP_PREFIX_PATH}/folder-creation-requests/list`,
        title: "folder_creation_requests",
        icon: CloudUploadOutlined,
        breadcrumb: false,
        submenu: [],
      }, //typicals-responses
      {
        key: "typical_responses",
        path: `${APP_PREFIX_PATH}/typicals-responses`,
        title: "Reponse Typique",
        icon: FileOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const eprescriptionRelatedNavTree = [
  {
    key: "eprescription",
    path: `${APP_PREFIX_PATH}/eprescription`,
    title: "eprescription",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "eprescription.ordenance",
        path: `${APP_PREFIX_PATH}/eprescription/new-medics/list`,
        title: "eprescription.newMedics",
        icon: MedicineBoxOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "eprescription.type",
        path: `${APP_PREFIX_PATH}/eprescription/typical/list`,
        title: "eprescription.typical",
        icon: ExceptionOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "eprescription.history",
        path: `${APP_PREFIX_PATH}/eprescription/history/list`,
        title: "eprescription.history",
        icon: HistoryOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const medicalExamRelatedNavTree = [
  {
    key: "medicalExams",
    path: `${APP_PREFIX_PATH}/medical-exams`,
    title: "Transport d'examen",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "medicalExams.newDemandes",
        path: `${APP_PREFIX_PATH}/medical-exams/new`,
        title: "Nouvelle Demande",
        icon: MedicineBoxOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "medicalExams.completed",
        path: `${APP_PREFIX_PATH}/medical-exams/completed`,
        title: "Termine",
        icon: FileProtectOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const CountNavTree = [
  {
    key: "count",
    title: "count",
    breadcrumb: false,
    submenu: [
      {
        key: "count.payment",
        path: `${APP_PREFIX_PATH}/payments`,
        title: "count.payment",
        icon: MoneyCollectOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "count.transaction",
        path: `${APP_PREFIX_PATH}/transaction`,
        title: "count.transaction",
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "count.settlements",
        path: `${APP_PREFIX_PATH}/settlements`,
        title: "count.settlements",
        icon: DollarOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const symposiumNavTree = [
  {
    key: "symposium",
    title: "symposium",
    breadcrumb: false,
    submenu: [
      {
        key: "symposium.article",
        path: `${APP_PREFIX_PATH}/articles`,
        title: "symposium.article",
        icon: BookOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "symposium.videos",
        path: `${APP_PREFIX_PATH}/videos`,
        title: "symposium.videos",
        icon: VideoCameraOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "symposium.publicity",
        path: `${APP_PREFIX_PATH}/publicity`,
        title: "symposium.publicity",
        icon: ThunderboltOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const teleconsultaion = [
  {
    key: "teleconsultation",
    title: "Teleconsultation",
    breadcrumb: false,
    submenu: [
      {
        key: "teleconsultation.list",
        path: `${APP_PREFIX_PATH}/tele-consultation/list`,
        title: "liste des prob",
        icon: ConsoleSqlOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [
  ...dashBoardNavTree,
  ...usersRelatedNavTree,
  ...invoices,
  ...appointments,
  ...homeServices,
  ...claims,
  ...teleconsultaion,
  ...consultationRelatedNavTree,
  ...eprescriptionRelatedNavTree,
  ...medicalExamRelatedNavTree,
  ...BiosensorNavTree,
  ...CountNavTree,
  ...symposiumNavTree,
];

export default navigationConfig;
