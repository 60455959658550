import { DEFAULT_PAGE_SIZE, TYPICAL_CONSULTATION_RESPONSE,} from "constants/ApiConstant"
import fetch from "../auth/FetchInterceptor";

const Service = {}


Service.findAll = async (pageNo, pageSize, search) => {
    const url = TYPICAL_CONSULTATION_RESPONSE + `?page=${pageNo}&size=${pageSize}&search=${search}`
    console.log(url)
    return await fetch.get(url).then(response => response)
}

Service.delete = async (medic) => {
    const url = TYPICAL_CONSULTATION_RESPONSE + `/${medic.id}/delete`+
    console.log(url, medic)
    return await fetch.delete(url).then(response => response)
}

export default Service;