import moment from "moment";

export function DateFormat(time) {
  let str =
    moment(time).format("DD/MM/YYYY HH:mm") 
   


  return str;
}
