import {
  Col,
  Descriptions,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Typography,
} from "antd";
import { CANCEL, SAVE } from "constants/contentText";
import React, { useEffect, useState } from "react";
import CustomButton from "utils/appointment/customButton";
import CustomCard from "utils/customCard";

import { usePatient } from "zustand/store/patientStore";

import { usePaymentMethods } from "zustand/store/paymentMethodsStore";
import { Option } from "antd/lib/mentions";

const { confirm } = Modal;

const PatientAccountDeposit = (props) => {
  const patient = props.location.state;
  const [addMode, setMode] = useState("");
  const [form] = Form.useForm();

  const { fetchPatientBalance, loading, patientBalance, updatePatientBalance } =
    usePatient((state) => ({
      fetchPatientBalance: state.fetchPatientBalance,
      loading: state.loading,
      patientBalance: state.patientBalance,
      updatePatientBalance: state.updatePatientBalance,
    }));

  const { paymentMethods, fetchPaymentMethods } = usePaymentMethods(
    (state) => ({
      paymentMethods: state.paymentMethods,
      fetchPaymentMethods: state.fetchPaymentMethods,
    })
  );

  useEffect(() => {
    fetchPatientBalance(patient?.id);
  }, [fetchPatientBalance, patient]);

  const onFinish = (values) => {
    values["patientId"] = patient?.id;
    values["amount"] = parseFloat(values?.amount);

    updatePatientBalance(values);
    setMode("");
  };

  const confirm = async () => {
    await fetchPaymentMethods();
    const payload = {
      patientId: patient?.id,
      amount: parseFloat(patientBalance),
      paymentMethodId: paymentMethods[0]?.id,
      type: "DEBIT",
    };

    updatePatientBalance(payload);
  };

  const cancel = (values) => {};

  return (
    <CustomCard
      title="Solde du patient"
      extra={<Typography> Solde :{patientBalance} MRU</Typography>}
      children={
        <Form
          form={form}
          initialValues={{
            amount: patient?.account?.balance,
          }}
          onFinish={onFinish}
        >
          <Descriptions key={patient.id} column={3}>
            <Descriptions.Item label={"Nom du patient"}>
              <strong>{patient?.fullName}</strong>
            </Descriptions.Item>
            <Descriptions.Item label={"Numero du patient"}>
              <strong>{patient?.phoneNumber}</strong>
            </Descriptions.Item>
            <Descriptions.Item label={"Date de naissance"}>
              <strong>{patient?.birthDate}</strong>
            </Descriptions.Item>
            <Descriptions.Item label={"Sexe"}>
              <strong>{patient?.gender}</strong>
            </Descriptions.Item>
            <Descriptions.Item label={"Age"}>
              <strong>{patient?.age}</strong>
            </Descriptions.Item>
          </Descriptions>

          {addMode === "DEPOSIT" && (
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  label="Montant"
                  name="amount"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label="Mode de paiement"
                  name="paymentMethodId"
                  rules={[
                    {
                      required: true,
                      message: "Mode de payment obligatoire",
                    },
                  ]}
                >
                  <Select placeholder="Selectionnner mode de payment">
                    {paymentMethods.map((method) => {
                      return <Option value={method?.id}>{method?.name}</Option>;
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Type d'operation"
                  name="type"
                  rules={[{ required: true, message: "Type est obligatoire" }]}
                >
                  <Select placeholder="Choisir type">
                    <Option value="CREDIT">Crediter</Option>
                    <Option value="DEBIT">Debiter</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            {!addMode && (
              <>
                <Popconfirm
                  title="Vider"
                  description="Are you sure to delete this task?"
                  onConfirm={() => confirm()}
                  onCancel={cancel}
                  okText="Oui"
                  cancelText="Non"
                >
                  <CustomButton
                    textbutton={"Vider le compte"}
                    type="danger"
                    ghost
                    style={{ marginLeft: 5, marginRight: 5 }}
                    handleClick={() => {
                      fetchPaymentMethods();
                    }}
                  />
                </Popconfirm>

                <CustomButton
                  textbutton={"Debiter | Crediter compter"}
                  type="primary"
                  handleClick={() => {
                    setMode("DEPOSIT");
                    fetchPaymentMethods();
                  }}
                />
              </>
            )}

            {addMode === "DEPOSIT" && (
              <>
                <CustomButton
                  textbutton={CANCEL}
                  handleClick={() => setMode("")}
                  type="danger"
                  style={{ marginLeft: 5, marginRight: 5 }}
                  ghost
                />
                <CustomButton
                  textbutton={SAVE}
                  htmlType="submit"
                  type="primary"
                />
              </>
            )}
          </div>
        </Form>
      }
      loading={loading}
    />
  );
};

export default PatientAccountDeposit;
